import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout-en"
import SEO from "../../components/seo-en"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import ImageChirurgien from "../../components/imageChirurgien-en"

const TemoignagesPage = () => (
  <Layout pathFr="/temoignages/">
    <SEO
      title="Témoignages de nos patients - Dr Philippe Roure"
      description="Témoignages et avis laissés par les patients du Dr Roure à la suite d'opération de chirurgie orthopédique du membre supérieur (maim, coude. épaule, poignet)."
    />
    <Helmet>
      <link
        rel="alternate"
        hreflang="fr"
        href={`https://www.docteurphilipperoure.com/temoignages/`}
      />
    </Helmet>
    <ImageChirurgien />
    <WrapperTitle>
      <Breadcrumb>
        <Link to="/en/">Home </Link>
      </Breadcrumb>
      <H1>Testimonials from our patients</H1>
      <p className="intro">
        Patients of Dr. Philippe Roure were kind enough to provide testimonials
        about their experience and treatment: from diagnosis of their pathology
        to post-operative follow-up. These can be found on the practice's{" "}
        <a
          href="https://www.google.com/maps/place/Dr+Philippe+Roure/@48.8527158,2.3104715,15z/data=!4m5!3m4!1s0x0:0x635c2eecfa224e06!8m2!3d48.8527158!4d2.3104715"
          target="_blank"
          rel="noreferrer noopener"
        >
          Google Maps
        </a>{" "}
        and{" "}
        <a
          href="https://www.facebook.com/drphilipperoure/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Facebook
        </a>
        .
      </p>
    </WrapperTitle>

    <Wrapper
      css={css`
        .testimonial {
          font-weight: 600;
          font-size: 13px;
          text-align: right;
          margin-top: -6px;
          margin-bottom: 30px;
          margin-right: 100px;

          ::before {
            content: "—";
            margin-right: 6px;
          }
          @media (max-width: 678px) {
            margin-right: 40px;
          }
        }
      `}
    >
      <p>
        "I consulted Dr. Roure because of wrist pain, I'd already had an
        operation performed by another surgeon in 2015 on this wrist but I had
        recurrent pain (sick leave every six months), and he told me it wouldn't
        be possible for me to practice my profession properly. In June 2018, I
        was injured again, my former surgeon gave up because he could not find
        the causes of my pain and tremors...
      </p>
      <p>
        So I decided to consult Dr Roure, who after two appointments decided to
        operate on me, and 3 months after the operation, I managed to go back to
        work smoothly, and today I can carry out my normal work, without any
        pain or discomfort, A BIG THANK YOU to Dr ROURE and his team, superb
        care, everything is clearly explained, with regular, attentive
        follow-up, the team is just great.
      </p>
      <p>I highly recommend Dr Roure." </p>
      <p class="testimonial">Kessy</p>

      <p>
        Extraordinary surgeon and individual with an equally extraordinary team,
        very professional and personable. I highly recommend Doctor Roure, who
        knew how to fix me up, able to provide relief where other surgeons
        couldn't... Thanks again."
      </p>

      <p class="testimonial">Aurélie R.</p>

      <p>
        "Doctor Roure is a very professional and attentive surgeon. Full
        detailed explanation given prior to the procedure. The whole team in the
        operating theatre is also very competent. My procedures, for a torn
        scapholunate ligament in my right wrist and torn ligament on my right
        thumb, went very well and without pain. I highly recommend this really
        excellent surgeon."
      </p>

      <p class="testimonial">Francisco B.</p>

      <p>
        "Almost one year since my right shoulder was operated on by Doctor
        Roure, everything has gone well and just as he explained to me. He'd
        warned me that I would have pain for quite a long time, and I was losing
        hope, but in the end everything returned to normal after a few
        physiotherapy sessions. All is well, thank you Doctor."
      </p>

      <p class="testimonial">Francis R.</p>

      <p>
        "My hand and wrist were operated on by Doctor Roure in July 2018. This
        surgeon is an ace with a scalpel!!! No pain, I'm still looking for the
        scar.... Everything was very well-explained to me by the surgeon. If
        ever you have a problem, you can place your trust in him.... His team
        and his secretaries are wonderful as are all the staff at the hand
        clinic in Aubergenville (78)."
      </p>

      <p class="testimonial">Martine P.</p>

      <p>
        "Third operation with the same team, always as professional and
        friendly. The pre- and post-op follow-up is top-notch. Dr. Roure works
        like a clock-maker, and the resulting scars are barely noticeable."
      </p>

      <p>
        A big thank you to Dr ROURE, his assistant Paula and all his team for
        the quality of their work."
      </p>

      <p>
        "After seeing a news report about Dr. Roure, I chose him to perform a
        biceps operation.
      </p>

      <p>
        Clearly I made the right choice, because in addition to the result,
        which was just as I had been hoping, the human setting in which the
        procedure was carried out was of an equally high quality - he proved to
        be an outstanding medical professional both in terms of his technical
        skill and his personal qualities, which is becoming increasingly rare
        these days."
      </p>

      <p class="testimonial">Stéphan L.</p>

      <p>
        "Very friendly and attentive staff. I particularly recommend Doctor
        Roure for his professionalism and kindness I came to see him for a
        shoulder problem that was bothering me every day (detachment of glenoid
        rim and tearing of the supraspinatus tendon). Since the operation, I no
        longer have any discomfort. THANK YOU"
      </p>

      <p class="testimonial">Cédric R.</p>

      <p>
        "Excellent surgeon, great professional and very friendly. According to
        my doctor, he saved my hands... I have complete faith in him, bravo
        Doctor and thank you for everything!"
      </p>

      <p class="testimonial">Christelle L.</p>

      <p>
        "A very good surgeon. I honestly recommend him. Operated on in 2016 for
        Dupuytren's contracture + carpal tunnel in my right hand. Operated on in
        2017 for Dupuytren's contracture + carpal tunnel in my right hand.
        Aubergenville Clinic. Very friendly team."
      </p>

      <p class="testimonial">Will</p>

      <p>
        "I had surgery for osteoarthritis in my left hand in 2017. This time I
        had the same operation on my right hand. Excellent surgeon. Thank you
        Doctor Roure. I have no more pain in my wrists and hands and I am very
        happy with my new life."
      </p>

      <p class="testimonial">Icham E.</p>

      <p>
        "I had three operations, without any problems, a great professional and
        very kind, explains things well."
      </p>

      <p class="testimonial">Véronique M. </p>

      <p>
        "He is an excellent doctor and surgeon who knows how to explain the
        causes and offer solutions when possible. I recommend him, I have had
        excellent follow-up and waiting times are not very long for an
        appointment. He has been treating me for 15 years. Do not hesitate to
        consult this competent professional."
      </p>

      <p class="testimonial">Nathalie F. </p>
    </Wrapper>
  </Layout>
)

export default TemoignagesPage

const WrapperTitle = styled.div`
  background-color: white;
  width: 78%;
  margin: 0 auto;
  border-radius: 7px;
  padding: 1.5em 2em 0.9em;
  margin-bottom: 45px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 1200px) {
    width: 100%;
    margin: 0 12px;
    padding: 1.35em 1.5em 0.7em;
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 100%;
    padding: 1.35em 1em 0.7em;
    margin: 0 0 20px;
  }
`
const Breadcrumb = styled.div`
  text-align: center;
  font-size: 0.9em;
  line-height: 1em;
  font-weight: 400;
  a {
    color: #919090;
    text-decoration: none;
  }
  @media (max-width: 678px) {
    font-size: 0.87em;
    margin-bottom: 10px;
  }
`

const H1 = styled.h1`
  font-size: 2.1em;
  @media (max-width: 678px) {
    font-size: 1.9em;
    line-height: 1.1em;
  }
`

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 2.25em 2em 1em 2em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 1200px) {
    margin: 0 12px 0;
  }
  @media (max-width: 1024px) {
    padding: 1em 1em 0.5em;
    margin: 0;
  }
`
